.f-wlc2 {
  display: box;
  display: -webkit-box;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased
}

body, html {
  width: 100%;
  height: 100%;
  position: relative
}

body {
  line-height: 1.15;
  font-size: .28rem;
  word-break: break-all
}

html, body, h1, h2, h3, h4, h5, h6, div, dl, dt, dd, ul, ol, li, p, blockquote, pre, hr, figure, table, caption, th, td, form, fieldset, legend, input, button, textarea, menu {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header, footer, section, nav, menu {
  display: block
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

th {
  text-align: left;
  font-weight: normal
}

html, body, fieldset, img, iframe, abbr {
  border: 0
}

i, cite, em, var, address, dfn {
  font-style: normal
}

li {
  list-style: none
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%
}

q:before, q:after {
  content: none
}

textarea {
  overflow: auto;
  resize: none
}

label, summary {
  cursor: default
}

h1, h2, h3, h4, h5, h6, em, strong, b {
  font-weight: normal
}

del, ins, u, s, a, a:hover {
  text-decoration: none
}

body, textarea, input, button, select, keygen, legend {
  color: #000;
  outline: 0;
  font-family: arial, sans-serif, "Microsoft YaHei"
}

img {
  width: 100%;
  display: block
}

.f-cb:after, .f-cbli li:after {
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  content: "."
}

.f-ib {
  display: inline-block;
  *display: inline;
  *zoom: 1
}

.f-db {
  display: block
}

.f-fl {
  float: left
}

.f-fr {
  float: right
}

.f-pr {
  position: relative
}

.f-oh {
  overflow: hidden
}

.f-tal {
  text-align: left
}

.f-tac {
  text-align: center
}

.f-tar {
  text-align: right
}

.f-toe {
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
  text-overflow: ellipsis
}

.f-wbba {
  word-break: break-all
}

.f-wlc2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

.f-tdit {
  text-decoration: line-through
}

.bg-cover {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover
}

.bg-contain {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain
}

a, button, input, canvas, textarea, div, span, i {
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
  outline: 0
}

/*a:active,button:active,input[type='button']:active,input[type='reset']:active,input[type='submit']:active{opacity:.8}a.disabled:active,button.disabled:active,input[type='button'].disabled:active,input[type='reset'].disabled:active,input[type='submit'].disabled:active{opacity:1}*/
.trans3d {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0)
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0
}

.row-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.column {
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -o-flex-direction: column
}

.col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0
}

.row-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start
}

.row-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end
}

.row-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center
}

.row-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch
}

.row-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  align-items: baseline
}

.col-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start
}

.col-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end
}

.col-center {
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.col-offset-10 {
  margin-left: 10%
}

.col-offset-20 {
  margin-left: 20%
}

.col-offset-25 {
  margin-left: 25%
}

.col-offset-33, .col-offset-34 {
  margin-left: 33.3333%
}

.col-offset-50 {
  margin-left: 50%
}

.col-offset-66, .col-offset-67 {
  margin-left: 66.6666%
}

.col-offset-75 {
  margin-left: 75%
}

.col-offset-80 {
  margin-left: 80%
}

.col-offset-90 {
  margin-left: 90%
}

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 10%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 10%;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%
}

.col-20 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%
}

.col-25 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.col-30 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%
}

.col-33, .col-34 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.3333%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 33.3333%;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 33.3333%
}

.col-40 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%
}

.col-50 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.col-60 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 60%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%
}

.col-66, .col-67 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.6666%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 66.6666%;
  -ms-flex: 0 0 66.6666%;
  flex: 0 0 66.6666%;
  max-width: 66.6666%
}
