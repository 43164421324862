.index-page {
  padding-bottom: calc(1.5rem + env(safe-area-inset-bottom, 20px));
  height: 100%;

  .adm-nav-bar {
    --height: .90rem;
    background-color: #fff;

    .adm-nav-bar-title {
      font-size: .25rem;
    }
  }

  .banner {
    height: 2.52rem;
    border-radius: .06rem;

    .banner-content {
      padding: .1rem .2rem .2rem;
      background-image: linear-gradient(#f0f9fd, #d6f1fd);

      .max-tips {
        color: #999;
        font-size: .24rem;
      }

      .price {
        color: #333;
        .unit {
          font-size: .28rem;
        }

        .value {
          font-size: .7rem;
        }
      }

      .add-price {
        margin-top: .15rem;
        font-size: .24rem;
        span {
          color: green;
        }

        .price-add {
          color: #268ccb;
        }

        .tag {
          margin-left: .05rem;
          padding: .03rem;
          display: inline-block;
          background-color: #268ccb;
          color: #fff;
          font-size: .20rem;
          border-radius: .06rem;
        }
      }
    }

    .banner-footer {
      padding: 0 .2rem;
      height: .6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      background-color: #fff;
      font-size: .24rem;
      color: #555;

      .arrow {
        width: .18rem;
        height: .18rem;
        transform: rotate(-90deg);
        color: #268ccb;
        font-size: .18rem;
      }

      .item {
        color: #666;
        span:nth-child(1) {
          margin-right: .06rem;
          color: #268ccb;
        }
      }
    }
  }

  .sell-type {
    margin-top: .1rem;
    // height: 2rem;
    .left {
      margin-right: .2rem;
    }

    div {
      height: 100%;
      background-color: lightcoral;
    }
  }

  .description {
    margin-top: -2.43rem;
    height: 2.4rem;
    background-color: lightblue;
  }

  .more {
    margin-top: .2rem;
    background-image: url(./images/u6.png);
    background-size: contain;
    background-repeat: no-repeat;

    .left {
      // background-color: lightblue;
    }
    .right {
      margin-left: .2rem;
      div {
        height: 1.96rem;
        // background-color: lightgreen;
      }

      .top-item {
        margin-bottom: .2rem;
      }
    }
  }
}

.index-tab {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(1.2rem + env(safe-area-inset-bottom, 20px));
  line-height: 1.5;
  background-color: #fff;
  font-size: .32rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  .common-item {
    width: 30%;
    line-height: 1.5;
    &:active {
      opacity: .3;
    }
  }

  .active {
    color: lightcoral;
  }

  // .circle {
  //   position: absolute;
  //   bottom: .4rem;
  //   left: 40%;
  //   width: 20%;
  //   height: 20vw;
  //   line-height: 20vw;
  //   border-radius: 50%;
  //   background-color: lightcoral;
  //   color: #fff;
  // }

  .ripple-bg {
    position: absolute;
    bottom: .4rem;
    left: 40%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: var(--adm-color-primary);
    // margin: 2rem auto;

    .circle {
      margin: 0 auto;
      position: absolute;
      z-index: 9;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      line-height: 1.5rem;
      &:active {
        opacity: .3;
      }
    }

    .text {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 1.5rem;
      text-align: center;

      > div {
        height: .35rem;
      }
    }
  } /* 共有样式 */
  .ripple1,
  .ripple2,
  .ripple3 {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: var(--adm-color-primary);
  } /* 单独样式 */
  /* 设置位置以及动画延迟 */
  .ripple1 {
    position: relative;
    // top: -.5rem;
    animation: ripple-anim 3s ease-out 0s infinite;
  }
  .ripple2 {
    position: relative;
    top: -1.5rem;
    animation: ripple-anim 3s ease-out 1s infinite;
  }
  .ripple3 {
    position: relative;
    top: -3rem;
    animation: ripple-anim 3s ease-out 2s infinite;
  } /* 动画效果 */

  .desc {
    text-align: center;
    color: var(--adm-color-primary);
  }
  @keyframes ripple-anim {
    0% {
      transform: scale(0.5, 0.5);
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    100% {
      transform: scale(2, 2);
      opacity: 0;
    }
  }
}
