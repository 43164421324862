html {
  touch-action: manipulation;
}

#app {
  padding-bottom: env(safe-area-inset-bottom, .2rem);
  min-height: 100%;
  height: 100%;
  background-color: #F5F6FA;
}

:root:root {
  --adm-color-primary: #ec808d;
  --adm-button-border-radius: .1rem;
}

* {
  // font-size: .28rem;
}

.content {
  padding: 0 .1rem;
}

.adm-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: .86rem;
  background-color: #fff;

  .adm-nav-bar-back-arrow {
    font-size: .34rem;
  }
  .adm-nav-bar-title {
    font-size: .36rem;
  }
}

.adm-button {
  padding: 0 0.2rem;
  height: 0.6rem;
}

.adm-button-large {
  height: .88rem;
  font-size: 0.36rem !important;
}

.adm-side-bar {
  --width: 2rem;
  --item-border-radius: .2rem;
  font-size: .28rem;
  .adm-side-bar-item {
    padding: .2rem .3rem;

    &-active {
      .adm-side-bar-item-highlight {
        display: none;
      }
    }
  }
}

.adm-form-item.adm-form-item-vertical {
  .adm-form-item-label {
    font-size: .3rem;
  }

  .adm-input-element {
    font-size: .3rem;
  }
}

.adm-picker {
  padding: .2rem;
  --item-height: .8rem;
  height: 5rem;

  .adm-picker-header-button {
    font-size: .3rem;
  }
}

.adm-toast-mask .adm-toast-main {
  min-width: 3.04rem;
  min-height: .8rem;
  line-height: .8rem;
  font-size: .3rem;
}

.adm-nav-bar {
  border-bottom-color: #e3e3e3 !important;
}
